"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useUnzer = void 0;
var _react = require("react");
var _UnzerProvider = require("../providers/UnzerProvider");
const useUnzer = () => {
  return (0, _react.useContext)(_UnzerProvider.UnzerContext);
};
exports.useUnzer = useUnzer;