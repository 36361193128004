"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isStandingOrderDeliveryTable = isStandingOrderDeliveryTable;
exports.jsonFields = void 0;
function isStandingOrderDeliveryTable(prop) {
  return prop ? prop.deliveries !== undefined : false;
}
const jsonFields = exports.jsonFields = ['c_orderingBillingAddress', 'c_orderingDeliveryTable', 'c_orderingNotificationsTable', 'c_orderingPayment', 'c_orderingShipping', 'c_orderingShippingAddress'];