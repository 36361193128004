"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useShopper = void 0;
var _react = require("react");
var _useBasket = require("./useBasket");
var _useCustomer = require("../../hooks/useCustomer");
/**
 * Joins basket and customer hooks into a single hook for initializing their states
 * when the app loads on the client-side. Should only be use at top-level of app.
 * @returns {Object} - customer and basket objects
 */
const useShopper = (opts = {}) => {
  const {
    currency,
    paymentError,
    orderNo,
    token
  } = opts;
  const customer = (0, _useCustomer.useCustomer)();
  const basket = (0, _useBasket.useBasket)({
    currency
  });
  const prevAuthType = (0, _react.useRef)();

  // Create or restore the user session upon mounting
  (0, _react.useEffect)(() => {
    customer.login();
  }, []);

  // Handle basket init/updates in response to customer/basket changes.
  (0, _react.useEffect)(() => {
    const hasBasket = basket === null || basket === void 0 ? void 0 : basket.loaded;

    // We have a customer but no basket, so we fetch a new or existing basket
    if (customer.isInitialized && !hasBasket) {
      basket.getOrCreateBasket({
        paymentError,
        orderNo,
        token
      });
      return;
    }

    // We have a customer and a basket, but the basket does not belong to this customer
    // so we get their existing basket or create a new one for them
    if (hasBasket && customer.isInitialized && customer.customerId !== basket.customerInfo.customerId) {
      basket.getOrCreateBasket({
        paymentError,
        orderNo,
        token
      });
      return;
    }

    // We have a registered customer (customer with email), and we have their basket,
    // but the email applied to the basket is missing or doesn't match the customer
    // email. In this case, we update the basket with their email.
    if (hasBasket && customer.isRegistered && customer.customerId === basket.customerInfo.customerId && customer.email !== basket.customerInfo.email) {
      basket.updateCustomerInfo({
        email: customer.email
      });
      return;
    }
  }, [customer.authType, basket.loaded]);

  // Call merge basket.
  (0, _react.useEffect)(() => {
    var _basket$giftCertifica;
    // Only call merge when there are items in the guest basket and you are
    // a returning customer.
    const shouldMerge = customer.authType === 'registered' && prevAuthType.current === 'guest' && !customer.isNew && (basket.itemCount > 0 || ((_basket$giftCertifica = basket.giftCertificateItems) === null || _basket$giftCertifica === void 0 ? void 0 : _basket$giftCertifica.length) > 0);
    if (shouldMerge) {
      basket.mergeBasket().then(() => {
        basket.removePaymentInstrument();
      });
    }

    // Update the current `authType` value.
    prevAuthType.current = customer.authType;
  }, [customer.authType]);
  return {
    customer,
    basket
  };
};
exports.useShopper = useShopper;