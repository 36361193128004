"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PAYMENT_METHODS = void 0;
const PAYMENT_METHODS = exports.PAYMENT_METHODS = {
  no_pay: 'NO_PAY',
  gift_card: 'GIFT_CERTIFICATE',
  unzer_cards: 'UNZER_CARDS',
  unzer_paypal: 'UNZER_PAYPAL',
  unzer_invoice: 'UNZER_INVOICE',
  unzer_direct_debit_secured: 'UNZER_DIRECT_DEBIT_SECURED',
  unzer_link_pay: 'UNZER_LINK_PAY'
};