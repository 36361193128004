"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.compareAddresses = void 0;
var _md = _interopRequireDefault(require("md5"));
const createAddressHash = address => {
  const concatedAddress = `${(address === null || address === void 0 ? void 0 : address.salutation) || null}-${(address === null || address === void 0 ? void 0 : address.firstName) || null}-${(address === null || address === void 0 ? void 0 : address.lastName) || null}-${(address === null || address === void 0 ? void 0 : address.address1) || null}-${(address === null || address === void 0 ? void 0 : address.address2) || null}-${(address === null || address === void 0 ? void 0 : address.countryCode) || null}-${(address === null || address === void 0 ? void 0 : address.postalCode) || null}-${(address === null || address === void 0 ? void 0 : address.city) || null}-${(address === null || address === void 0 ? void 0 : address.companyName) || null}`;
  return (0, _md.default)(concatedAddress);
};
const compareAddresses = (addressOne, addressTwo) => {
  const addressOneHash = createAddressHash(addressOne);
  const addressTwoHash = createAddressHash(addressTwo);
  return addressOneHash === addressTwoHash;
};
exports.compareAddresses = compareAddresses;