"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateBody = void 0;
const validateBody = (config, validator) => (req, res, next) => {
  if (req !== null && req !== void 0 && req.body) {
    try {
      if (validator(req.body)) {
        return next();
      }
    } catch (error) {
      res === null || res === void 0 ? void 0 : res.status(400);
      throw new Error(config !== null && config !== void 0 && config.isProduction ? 'The request is malformed' : (error === null || error === void 0 ? void 0 : error.message) || error);
    }
  }
  res === null || res === void 0 ? void 0 : res.status(400);
  throw new Error('The body is missing');
};
exports.validateBody = validateBody;